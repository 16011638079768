<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
  
        <div class="va-card">
          <div class="row">
            <div class="col-lg-12">
              <div class="dash_card_body pb-0">
                <div class="header_section">
                  <h4 class="menu_header"> <span>Future Orders</span></h4>
                  
                </div>
                <div class="search_section mb-0">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group has-search">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="orderSearch" @Keyup="searchOrders()" class="form-control"
                          placeholder="Search">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mr-2" @click="searchOrders('search')">
                          <span v-if="searchingType == 'search'"><span v-html="searchspinner"></span></span> Search
                        </button>
                      </div>
                    </div>
      
                     
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="row">
                        <div class="col-md-3 pad-r-0">
      
                          <Multiselect v-model="storeSearch" @change="searchOrders()" :close-on-select="true"
                            :options="strsoptions" :custom-label="name" :searchable="true"
                            :placeholder="'Stores'" label="name" track-by="name"></Multiselect>
                        </div>
                        <div class="col-md-3 pad-r-0">
                          <select class="form-control" v-model="searchChannel" @change="searchOrders()">
                            <option value="">Channel</option>
                            <option value="FOODIE">FOODIE</option>
                            <option v-for="channel in channels" :key="channel.id" :value="channel.channel_name">
                              {{channel.channel_name}}</option>
                          </select>
                        </div>

                      </div>
                    </div>
                  </div>
      
                  <!--Status menu start-->
                  <div class="St-menu">
                    <div class="status_btn_group"></div>
                    <button :class="[{'active': statusType == ''},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('')">
                      <span >All</span>
                    </button>
                    <button :class="[{'active': statusType == 'Pending'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('Pending')">
                      <span >Pending</span>
                    </button>
                    <button :class="[{'active': statusType == 'Failed'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('Failed')">
                      <span >Failed</span>
                    </button>
                    <button :class="[{'active': statusType == 'Completed'},'btn-filter mr-1 mt-1']" @click="filterPaymentStatus('Completed')">
                      <span >Completed</span>
                    </button>
                  </div>
                  <!--Status menu End-->
                </div>
              </div>
              <hr>
            </div>
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Order Ref</th>
                        <th scope="col">Store</th>
                        <th scope="col">Channel</th>
                        <th scope="col">User</th>
                        <th scope="col"> Total </th>
                        <th scope="col">Payment Status</th>
                        <th scope="col">Order Status</th>
                          
                        <th scope="col">Delivery Status</th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="(order,oindx) in  orders" :key="oindx">
                        <td>
                          <a class="title_text" href="javascript:void(0)" data-toggle="modal"
                            :data-target="'#orderDetail_'+order.id"> {{order.channel_display_id}}</a> <br> <br>
  
                          <router-link :to="{ name: 'order_details', params: { order_id:order.id }}">
                            <span class="title_text mt-1">view more</span>
                          </router-link>
                        </td>
                        <td>
                          {{order.store}} <br>
                          <span class="mt-4  text-muted"><small>{{formatDate(order.created_at,4)}}</small></span>
                          <div v-if="order.fargo ==1">
                            <span class="mt-2 pending">
                              <strong>Future Order Date:</strong> 
                              {{formatDate(order.fargo_on,3)}}
                            </span>
                          </div>
                        </td>
                        <td>
                          {{order.channel}}
                        </td>
  
                        <td>
                          <span>{{order.customer_name}}</span> <br />
                        </td>
                        <td class="td-price">
                          {{order.currency}} {{order.total_price > 0 ? formatPrice(order.total_price)
                            :formatPrice(order.sub_total_price + order.shipping_cost)}}
                        </td>
                        <td>
                          <span :class="[{'active': order.payment_status.toLowerCase() === 'completed'},{'disabled':order.payment_status.toLowerCase() === 'failed'},{'pending': order.payment_status === ''}]">
                            <strong>{{order.payment_status ? order.payment_status : 'PENDING'}}</strong>
                          </span>
                          <span  v-if="order.payment_code">
                            <br> <br>
                            <strong>Code:
                            </strong>{{order.payment_code}}</span>
                            
                          <span v-if="order.user !=''">
                            <br><br>
                            <strong>Created by: {{order.user}}</strong>
                          </span>
                          <br><br>
                          <span v-if="order.channel =='FOODIE' && order.payment_status == '' || order.channel =='FOODIE' && order.payment_status == 'PENDING'">
                            <button class="btn btn-sm btn-default custom_btn custon_orange"
                              @click="payNow(order.id)"><span> Pay Now</span></button>
                          </span>
                            
  
                        </td>
                        <td>
                          <span v-if="order.status">
                            {{order.status}}
                          </span>
                        </td>
                          
                        <td>
                          <span>
                            {{order.shipping_method ? order.shipping_method === 'flat' ? 'flatrate' :
                              order.shipping_method : 'N/A' }}
                          </span>
                        </td>
                        <!--  -->
                        <!-- Modal -->
                        <div class="modal order_modal right" :id="'orderDetail_'+order.id"
                          aria-labelledby="myModalLabel2">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <!-- {{order}} -->
                              <div class="modal-header order_modal_header">
                                <h3 class="modal-title">Order <b>{{order.channel_display_id}}</b> </h3>
                                <button type="button" class="close button_close" data-dismiss="modal"
                                  aria-label="Close"><span aria-hidden="true">&times;</span></button>
                              </div>
  
                              <div class="modal-body">
                                <div class="padd">
                                  <div class="sec customer-D bd-b">
                                    <h4 class="c-title">Customer Details</h4>
                                    <span v-if="order.customer_name"><strong>Name: </strong>{{order.customer_name}}
                                      <br></span>
                                    <span v-if="order.customer_phone"><strong>Phone: </strong>{{order.customer_phone}}
                                      <br></span>
                                    <span v-if="order.customer_phone_code"> <strong>Phone Code:</strong>
                                      {{order.customer_phone_code}} <br></span>
  
                                    <span v-if="order.map_delivery_address"><strong>Address:
                                    </strong>{{order.map_delivery_address}} <br></span>
                                    <span v-if="order.delivery_address"><strong>More details:
                                    </strong>{{order.delivery_address}} <br></span>
  
                                  </div>
                                    
                                  <div class="sec bd-b">
                                    <h4 class="c-title">Order Details</h4>
                                    <table class="order-table" width="100%" cellspacing="0" cellpadding="0">
                                      <tbody>
                                        <tr v-for="(item,itemindx) in order.items" :key="item.id">
                                          <td width="60%" valign="top"><b>{{itemindx + 1}}. {{item.name}}</b>
  
                                            <div class="indent" v-if="item.sub_items.length > 0">
                                              <ul>
                                                <li v-for="sub_item in item.sub_items" :key="sub_item">
                                                  <b>{{sub_item.modifier_group}}:</b> <span>{{sub_item.name}}</span>
                                                </li>
                                              </ul>
                                            </div>
                                            <span v-if="item.special_instructions !==''"><b>Note</b>:
                                              <span>{{item.special_instructions}}</span></span>
                                          </td>
                                          <td class="text-right" width="10%" valign="top">
                                            <b>{{item.quantity}}</b> 
                                            <div class="indent_children" v-if="item.sub_items.length > 0">
                                              <ul >
                                                <li  v-for="sub_item in item.sub_items" :key="sub_item">
                                                  <span>
                                                    x {{sub_item.quantity}}
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                          <td class="text-right" width="30%" valign="top">
                                            <b>{{formatPrice(item.price_total)}}</b>
                                            <div class="indent_children" v-if="item.sub_items.length > 0">
                                              <ul >
                                                <li  v-for="sub_item in item.sub_items" :key="sub_item">
                                                  <span>
                                                    <b>{{formatPrice(sub_item.price_total)}}</b>
                                                  </span>
                                                </li>
                                              </ul>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td class="text-right" colspan="2" valign="top" align="right">Order Amount:
                                          </td>
                                          <td class="text-right" valign="top"> <b>{{order.currency}}
                                            {{formatPrice(order.sub_total_price)}}</b></td>
                                        </tr>
                                        <tr>
                                          <td class="text-right" colspan="2" valign="top" align="right">Delivery Cost:
                                          </td>
                                          <td class="text-right" valign="top"><span
                                                                                v-if="order.shipping_cost"><b>{{formatPrice(order.shipping_cost)}}</b></span>
                                            <span v-else><b>0.00</b></span></td>
                                        </tr>
                                        <tr>
                                          <td class="text-right" colspan="2" valign="top" align="right">Order Total: </td>
                                          <td class="text-right" valign="top"> <b>{{order.currency}} {{order.total_price > 0 ?
                                            formatPrice(order.total_price) : formatPrice(order.sub_total_price +
                                              order.shipping_cost)}}</b></td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
  
                                  <div class="sec bd-b payment-D">
  
                                    <h4 class="c-title">Payment Details</h4>
                                    <span class="left"
                                      v-if="order.payment_status">{{order.payment_status}}</span>
                                    <span class="right" v-if="order.payment_method"><strong>Method:
                                    </strong>{{order.payment_method}}</span>
                                    <span  v-if="order.payment_id"><strong>ID:
                                    </strong>{{order.payment_id}}</span>
                                    <span  v-if="order.payment_code"><strong>Code:
                                    </strong>{{order.payment_code}}</span>
                                    <span  v-if="order.user_id != 0"><strong>Updated By:
                                    </strong>{{order.user}}</span>
                                    <div v-if="order.vouchers &&  order.vouchers.length > 0">
                                      
                                      <hr>
                                      <h4 class="c-title">Voucher Redemption Details</h4>
                                      <div></div>
                                      <span v-for="voucher in order.vouchers" :key="voucher.voucher_code">
                                        <b>Code: </b> {{voucher.voucher_code}} &nbsp; <b> Amount: </b> {{voucher.amount}}
                                        <br>
                                      </span> <br>
                                    </div>
                                    <hr>
                                    <div class="px-1 mt-2 mb-2" v-if="order.channel">
                                      
                                      <span class="p-title">Order Channel: </span>
                                      <span  v-if="order.channel">{{order.channel}}</span>
                                    </div>
                                    <hr>
                                      
                                  </div>
  
                                  <div class="sec bd-b">
                                    <div class="delivery_details" v-if="order.shipping_method">
                                      
                                      <h4 class="c-title">Delivery Details</h4>
                                      <span> <b>Method:</b>{{order.shipping_method}}<br></span>
                                    </div>
  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="9">
                          <div id="pagination" v-if="totalPages > 1">
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                  <option :value="100">100</option>
                                </select>
                              </li>
  
                            </ul>
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page"
                              @pagechanged="onPageChange" />
  
                          </div>
  
                        </td>
                      </tr>
                    </tfoot>
                  </table>
  
                </div>
                <div v-if="loading" class="loading-cover">
                  <div class="loader"> </div>
                </div>
  
              </div>
            </div>
  
          </div>
               
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import moment from 'moment';
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  import Pagination from '@/pages/pagination/pagination.vue';
  import Multiselect from '@vueform/multiselect';
  export default {
      components: {
          Pagination,
          Multiselect
      },
      data() {
          return {
              statusType: '',
              orders: [],
              start: '',
              end: '',
              orderSearch: '',
              stores: [],
              orderItem: [],
              searchingType: '',
              loading: false,
              page: 1,
              orderDelay: '',
              totalPages: 0,
              totalRecords: 0,
              recordsPerPage: 50,
              debounce: null,
              storeSearch: '',
              menuItems: [],
              orderStatus: '',
              paymentStatus: '',
              productSearch: '',
              strsoptions: [],
              productOptions: [{
                "value": '',
                "name": "All Products"
              }],
              itemPosId: '',
              enterpageno: '',
              spinner: '',
              exportType: '',
              exportData: [],
              channels: [],
              isReadyspinner: false,
              prepTime: 0,
              searchChannel: '',
              statusSpinner: false,
              searchspinner: "<i class='fa fa-spinner fa-spin '></i>"
          }
      },
      beforeMount: async function () {
          this.getOrders();
          this.getBranches();
          this.getItems();
          this.getChannels();
      },
      computed: {
          minDate() {
              return this.start;
          }
      },
      methods: {
          filterPaymentStatus(type){
            this.paymentStatus = type
            this.statusType = type
            this.getOrders();
          },
          clearSearch() {
              this.searchingType = 'clear';
              this.productSearch = '';
              this.paymentStatus = '';
              this.storeSearch = '';
              this.start = '';
              this.end = '';
              this.orderStatus = '';
              this.exportType = '';
              this.getOrders();
          },
          customFormatter(date) {
              return moment(date).format('YYYY-MM-DD');
          },
          exportOrder(type) {
              this.searchingType = type;
              this.exportType = type;
              this.getOrders();
          },
          async getChannels() {
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              const url = this.baseUrl + "/third-party-setting?merchant_id=" + this.merchantID;
              this.axios.get(url, config).then((response) => {
                  if (response.status === 200) {
                      this.channels = response.data.data
                  }
              });
          },
          
         
          searchOrders(type = '') {
              if (type !== '') {
                  this.searchingType = type;
              }
              clearTimeout(this.debounce);
              this.debounce = setTimeout(() => {
                  this.getOrders();
              }, 1000)
          },
          getOrders() {
              this.loading = true;
              this.statusType = this.paymentStatus
              let url = `${this.dashBaseUrl}/recent-orders/${this.merchantID}`;
              url = new URL(url);
              url.searchParams.set('channel', 'FOODIE');
              url.searchParams.set('merchant_id', this.merchantID);
              url.searchParams.set('future_order', 1);
              if (this.exportType === '') {
                  url.searchParams.set('page', this.page);
                  url.searchParams.set('size', this.recordsPerPage);
              }
              if (this.orderSearch !== '') {
                  url.searchParams.set('search', this.orderSearch);
              }
              if (this.searchChannel !== '') {
                  url.searchParams.set('channel', this.searchChannel);
              }
              if (this.storeSearch !== '') {
                  url.searchParams.set('branch', this.storeSearch);
              }
              if (this.orderStatus !== '') {
                  url.searchParams.set('order_status', this.orderStatus);
              }
              if (this.paymentStatus !== '') {
                  this.paymentStatus = this.paymentStatus !='Pending' ? this.paymentStatus : ''
                  url.searchParams.set('payment_status', this.paymentStatus);
              }
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              this.axios.get(url, config).then((response) => {
                  if (response.status === 200) {
                    this.orders = response.data.data.data;
                    this.totalPages = Math.ceil(response.data.data.total / this.recordsPerPage)
                    this.totalRecords = response.data.data.total;
                  }
              }).catch((error) => {
                if (error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
              }).finally(() => {
                  this.loading = false;
                  this.searchingType = '';
              })
          },
          
          async payNow(id){
            this.statusSpinner = id;
            try{
              let url = `${this.dashBaseUrl}/payment-page/${this.merchantID}`;
              url = new URL(url);
              url.searchParams.set('order_id', id);
              const response = await this.axios.get(url,this.apiRequestConfig())
              if(response.status === 200){
                // window.location = response.data.data;
  
                window.open(response.data.data, '_blank');
                this.statusSpinner = false;
              }
            }catch(error){
              this.statusSpinner = false;
              if(error.response.status === 401){
                this.$router.push({name:'login'})
              }
            }
  
          },
          async getBranches() {
              const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;
              this.axios.get(url, this.apiRequestConfig()).then((response) => {
                  if (response.status === 200) {
                      if (response.data.data !== null) {
                          const stores = response.data.data;
                          this.stores = stores;
                          this.strsoptions.push({
                                        "value": "",
                                        "name": "All Stores ("+ stores.length +")"
                          })
                          for (let i = 0; i < stores.length; i++) {
                              this.strsoptions.push({
                                  "value": stores[i].id,
                                  "name": stores[i].branch_name
                              })
                          }
                      }
                  }
              }).catch((error) => {
                if (error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
              })
  
          },
          getItem(item) {
              const OrderStatus = [];
              if (this.itemPosId !== '') {
                  const orderItems = item.items;
                  this.orderItem = [];
                  for (let i = 0; i < orderItems.length; i++) {
                      const pposId = orderItems[i].pos_id;
                      if (pposId.trim() == this.itemPosId.trim()) {
                          OrderStatus.push(orderItems[i]);
                      } else {
                          for (let x = 0; x < orderItems[i].sub_items.length; x++) {
                              const sposId = orderItems[i].sub_items[x].pos_id;
                              if (sposId.trim() == this.itemPosId.trim()) {
                                  OrderStatus.push({
                                      name: `${orderItems[i].sub_items[x].name} (${orderItems[i].name})`
                                  });
                              }
                          }
                      }
                  }
              }
              this.orderItem = OrderStatus;
          },
  
          async getItems() {
              let url = `${this.baseUrl}/menu_item`;
              url = new URL(url);
              url.searchParams.set('merchant_id', this.merchantID);
              const config = {
                  headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
              };
              this.axios.get(url, config).then((response) => {
                  this.menuItems = response.data.data
                  for(let x=0; x< this.menuItems.length; x++){
                    this.productOptions.push({
                      "value": this.menuItems[x].menu_item.id,
                      "name":this.menuItems[x].menu_item.item_name
                    })
  
                  }
                  // this.productOptions
              }).catch((error) => {
                if (error.response.status === 401) {
                              this.$router.push({
                                  name: 'login'
                              });
                      }
              })
          },
          onPageChange(page) {
              this.page = page
              this.getOrders();
          },
          onChangeRecordsPerPage() {
              this.getOrders();
              // ds
          }
      },
      mixins: [ReusableFunctionMixins, ReusableDataMixins]
  }
  </script>
  